import { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { LoadingScreen } from 'components';

function Routes() {
  const Intro = lazy(() => import('./pages/Intro'));
  const Main = lazy(() => import('./pages/Main'));
  const Result = lazy(() => import('./pages/Result'));
  const Error404 = lazy(() => import('./pages/Error404'));

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route exact path='/' component={Intro} />
        <Route exact path='/redacao' component={Main} />
        <Route exact path='/resultados' component={Result} />
        <Route exact path='/error404' component={Error404} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
