import { useEffect, useState } from 'react';
import { axios } from 'utils/api';
import { useQuery } from 'utils/hooks';
import { Header, Block } from './styles';
import logos from 'assets/logos.png';

function TopBar() {
  const query = useQuery();
  const [company, setCompany] = useState();

  useEffect(() => {
    const fetchCompanies = async () => {
      const companyId = query.get('company_id');
      if (!companyId) return;
      try {
        const { data } = await axios.get('/companies');
        setCompany(data.data.find((item) => item.id === Number(companyId)));
      } catch (error) {
        console.log(error);
      }
    };
    fetchCompanies();
    //eslint-disable-next-line
  }, []);

  if (!company) return null;

  return (
    <Header>
      <Block mt='0' mb='0' ml='20px'>
        <span>Vestibular - {company.name}</span>
      </Block>
      <Block mt='0' mb='0' mr='20px' style={{ width: '50%' }}>
        <img src={logos} alt='company logos' width='50%' style={{ float: 'right' }} />
      </Block>
    </Header>
  );
}

export default TopBar;
