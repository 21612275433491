import Countdown from 'react-countdown';
import { toast } from 'react-toastify';
import { Column, ClockArea } from 'components/styles';

function PaperHeader(props) {
  const { text, date } = props;

  const handleComplete = () => {
    toast.warn('⚠️ O seu tempo se esgotou! Envie a sua redação.', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  if (!date) return null;

  return (
    <ClockArea>
      <Column>
        <span>Tempo Restante: </span>
        <Countdown onComplete={handleComplete} date={date} />
      </Column>
      <Column>
        <span>Contador de Caracteres: </span>
        <span>{text.length}/2500</span>
      </Column>
    </ClockArea>
  );
}

export default PaperHeader;
