import { BrowserRouter as Router } from 'react-router-dom';
import { TopBar } from 'components';
import { Container, GlobalStyle } from './styles';
import { ToastContainer } from 'react-toastify';
import Routes from './Routes';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <GlobalStyle />
      <TopBar />
      <ToastContainer />
      <Container>
        <Routes />
      </Container>
    </Router>
  );
}

export default App;
