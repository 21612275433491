import { useState, useEffect } from 'react';
import { useQuery } from 'utils/hooks';
import { Redirect, useHistory } from 'react-router-dom';
import { axios } from 'utils/api';
import { redirectUrl } from 'utils/env';
import { LoadingScreen } from 'components';

function QueryWrapper(props) {
  const { children } = props;
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    const fetchValid = async () => {
      try {
        const { data } = await axios.get('/dissertations', {
          params: {
            userCode: query.get('user_code'),
          },
        });
        if (data.data.length === 0) {
          setLoading(false);
        } else {
          window.location.assign(`${redirectUrl}/areacandidato/${query.get('hash')}`);
        }
      } catch (error) {
        history.push('/error404');
      }
    };
    fetchValid();
  }, [query, history]);

  if (
    !query.get('user_code') ||
    !query.get('user_name') ||
    !query.get('company_id') ||
    !query.get('hash')
  )
    return <Redirect to='/error404' />;

  if (loading) return <LoadingScreen />;

  return children;
}

export default QueryWrapper;
