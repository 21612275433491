import { useState } from 'react';
import { StyledButton, ButtonSpinner as Spinner } from './styles';

function Button(props) {
  const { onClick, children, ...rest } = props;
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    if (!onClick) return;
    setLoading(true);
    try {
      await onClick();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledButton disabled={loading} onClick={handleClick} {...rest}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {loading && <Spinner />}
        {children}
      </div>
    </StyledButton>
  );
}

export default Button;
