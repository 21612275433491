import { Container, Spinner } from './styles';

function LoadingScreen() {
  return (
    <Container>
      <div style={{ margin: '40vh' }}>
        <Spinner />
      </div>
    </Container>
  );
}

export default LoadingScreen;
