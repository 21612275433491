import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

export const ClockArea = styled.div`
  display: flex;
  color: #ffffff;
  background-color: #EF1860;
  padding: 1rem;
  margin:  0 0 1rem 0;
  font-weight: bold;
  text-align: center;

  @media screen and (min-width: 900px){
    margin:  0 50px 10px 50px;
    font-weight: bold;
    text-align: center;
  }
`

export const Tag = styled.span`
  background-color: #EF1860;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  margin-top: 1rem;
`

export const Title = styled.h1`
  color: ${props => props.color || '#EF1860'};
  margin-top: 0;
  text-align: ${props => props.center && 'center'}
`

export const Subtitle = styled.h3`
  color: #EF1860;
`

export const Separator = styled.hr`
  margin: ${props => (props.bigSpace 
    ? '100px 0 80px 0' 
    : props.smallSpace 
    ? '30px 0 20px 0' 
    : '60px 0 40px 0')};
  border: none;
  border-top: 3px solid #EF1860;
  width: 5%;
`

export const Header = styled.header`
  padding: 15px 0;
  width: 100vw;
  max-width: 100%;
  margin: 0 grid-auto-columns;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #EF1860;
  color: #ffffff;
  font-size: 1.5em;
  font-weight: 500;
  letter-spacing: 2px;
`

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 100%;
  padding: ${props => props.padding || '5vh'};
`

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  border: 5px solid #f3f3f3;
  -webkit-animation: spin 1s linear infinite;
  animation: ${spin} 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
  width: 2em;
  height: 2em;
`;

export const ButtonSpinner = styled.div`
  border: 3px solid #f3f3f3;
  -webkit-animation: spin 1s linear infinite;
  animation: ${spin} 1s linear infinite;
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 1.4em;
  height: 1.4em;
  margin-right: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 900px){
    justify-content: space-around;
    flex-direction: row;
    margin: ${props => props.margin || '40px 0 0 0'};
  }
`

export const Column = styled.div`
  max-width: 100vw;
  padding: 0 1rem;
  overflow-x: hidden;
  
  @media screen and (min-width: 900px){
    padding: ${props => props.padding || '1rem 2rem'};
    width: ${props => props.width || '50%'};
    overflow-x: ${props => props.scroll};
    max-height: ${props => props.maxHeight};
  }
`

export const Paper = styled.div`
  position: relative;
  width: 90%;
  min-width: 50vw;
  height: 390px;
  margin: 0 auto;
  background: #fafafa;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0,0,0,.3);
  overflow: hidden;

@media screen and (min-width: 900px){
  min-width: 400px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 60px;
    background: radial-gradient(#575450 6px, transparent 7px) repeat-y;
    background-size: 30px 30px;
    border-right: 3px solid #EF1860;
    box-sizing: border-box;
  }
}
`

export const PaperContent = styled.div`
  position: absolute;
  top: 3rem; right: .5rem; bottom: 3rem; left: .5rem;
  background: linear-gradient(transparent, transparent 28px, #91D1D3 28px);
  background-size: 30px 30px; 

  @media screen and (min-width: 900px){
    top: 30px; right: 0; bottom: 30px; left: 60px;
  }
`

export const TextArea = styled.textarea`
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    line-height: 30px;
    padding: 0 10px;
    border: 0;
    outline: 0;
    background: transparent;
    font-family: cursive;
    font-weight: bold;
    font-size: 18px;
    box-sizing: border-box;
    z-index: 1;
    resize: none;
`

export const Placeholder = styled.div`
 height: 250px;
 border: 1px solid black;
 width: 100%;
`
export const StyledButton = styled.button`
  appearance: none;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 800;
  font-size: 0.75rem;
  height: 3rem;
  line-height: 3.075rem;
  padding: 0 2.5rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.025rem;
  color: #ffffff;
  background-color: ${props => props.backgroundColor || '#EF1860'};

  &:hover {
    filter: brightness(130%);
  }

  &:disabled {
    filter: brightness(85%)
  }
`

export const NavButton = styled(Link)`
  appearance: none;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 800;
  font-size: 0.75rem;
  height: 3rem;
  line-height: 3.075rem;
  padding: 0 2.5rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.025rem;
  color: #ffffff;
  background-color: #EF1860;

  &:hover {
    background-color: #F26191;
  }
`

export const Block = styled.div`
  margin-top: ${props => props.mt || '20px'};
  margin-bottom: ${props => props.mb || '30px'};
  margin-left: ${props => props.ml || '0'};
  margin-right: ${props => props.mr || '0'};
  text-align: ${props => props.center && 'center'};
`

export const Video = styled.video`
  width: 100%;
  border-radius: 5px;
  margin: 1rem 0;
  
  @media screen and (min-width: 900px){
    margin: 0;
  }
`

export const Image = styled.img`
  width: ${props => props.width || '100%'};
  margin: ${props => props.margin || '0'};
`

export const Centered = styled.div`
  align-items: center;   
  display: flex;
  flex-direction: column;
  height: ${props => props.height || '80vh'};
  justify-content: center;
  width: ${props => props.width || '100%'};
`
export const ModalContainer = styled.div`
  position: absolute;
  background-color: rgba(0,0,0,.5);
  z-index: 999; 
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

export const ModalInner = styled.div`
  width: 20rem; 
  height: 30rem;
  background-color: white;
`

export const ModalHeader = styled.div`
  height: 40%;
  background-color: ${props => props.backgroundColor || '#EF1860'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ModalBottom = styled.div`
  padding: 1rem;
  display: flex; 
  flex-direction: column;
  align-items: center;
`

export const ModalMessage = styled.p`
  text-align: center;
`